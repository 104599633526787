import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchCountries(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/countries', {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addCountry(ctx, countryData) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/countries', countryData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateCountry(ctx, {id, data}) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/countries/' + id, data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteCountry(ctx, countryId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete('/countries/' + countryId)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCountry(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/countries/' + id)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }
    }
}
